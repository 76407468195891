<template>
  <div class="tos-home-footer">
    <div class="topline"></div>
    <div class="linklist">
      <a href="https://deployreality.com/terms-of-service/" rel=noopener target=_blank class="f8 homelink">{{ $t('Terms of Service') }}</a>
      <div class="linklistline"></div>
      <a href="https://deployreality.com/privacy/" rel=noopener target=_blank class="f8 homelink">{{ $t('Privacy Policy') }}</a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    showLicenseAgreement: {
      type: Boolean,
      default: true,
    },
  },
})
export default class TOSHomeFooter extends Vue {}
</script>

<style lang="postcss" scoped>
@import '../styles';

.tos-home-footer {
  width: 100%;
  padding: var(--spacingSm) 0;
  background-color: var(--colorCasper);
  margin-top: var(--spacingMd);

  & .topline {
    width: 100%;
    height: 0.1rem;
    background-color: var(--colorManatee);
  }

  & .linklist {
    padding: var(--spacingSm) 0 0;
    display: grid;
    grid-gap: var(--spacingSm);
    grid-auto-flow: column;
    grid-auto-columns: minmax(auto, max-content);
    align-items: center;

    & .homelink {
      color: var(--colorManatee);
      text-decoration: none;
    }

    & .linklistline {
      width: 0.1rem;
      height: 1rem;
      background-color: var(--colorManatee);
    }
  }
}
</style>
